import React, { Component } from 'react'
import Layout from '../layout/layout'
import Helmet from 'react-helmet'
import ScrollManager from '../helper/scrollManager'
import { easeFunction } from '../helper/variables'
import posed from 'react-pose'
import { connect } from 'react-redux'
import HeroBlock from '../components/serviceBlocks/heroBlock'
import HeadImage from '../components/common/headImage'
import Parser from 'html-react-parser'

import './career.css'
import { BrowserView, isBrowser } from 'react-device-detect'
import Footer from '../components/common/footer'
import Application from '../components/careersBlock/application'
import _ from 'lodash'
import { graphql } from 'gatsby'
import SectionIndicator from '../components/common/sectionIndicator'
import ReactGA from 'react-ga'

const PageTransition = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
})

class Career extends Component {
  state = {
    mainNode: null,
    scrollValue: 0,
    momentumScrollValue: 0,
    windowHeight: 0,
    triggerPointApplication: 0,
    triggerPointFooter: 0,
  }

  constructor(props) {
    super(props)

    this.mainNode = React.createRef()
    this.sectionApplication = React.createRef()
    this.sectionFooter = React.createRef()
  }

  componentDidMount() {
    ReactGA.initialize('UA-48349543-1')

    if (typeof window !== 'undefined')
      ReactGA.pageview(window.location.pathname + window.location.search)

    if (typeof window !== 'undefined')
      window.addEventListener('resize', this.getTriggerPoints)

    this.getTriggerPoints()

    this.setState({
      mainNode: this.mainNode.current,
    })

    this.props.backgroundWhite()
    this.props.noHomepageLoading()
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('resize', this.getTriggerPoints)

    this.props.backgroundWhite()
  }

  getTriggerPoints = () => {
    const windowHeight = typeof window !== 'undefined' && window.innerHeight
    const { momentumScrollValue } = this.state

    this.setState({
      windowHeight,
      triggerPointApplication:
        momentumScrollValue +
        this.sectionApplication.current.getBoundingClientRect().top,
      triggerPointFooter:
        momentumScrollValue +
        this.sectionFooter.current.getBoundingClientRect().top,
    })

    return windowHeight
  }

  updateMomentumScrollValue = value => {
    this.setState({
      momentumScrollValue: value,
    })

    if (value > this.state.triggerPointFooter - (isBrowser ? 75 : 55)) {
      this.props.backgroundBlack()
    } else {
      this.props.backgroundWhite()
    }
  }

  updateScrollValue = value => {
    this.setState({
      scrollValue: value,
    })
  }

  getMargin = momentumScrollValue => {
    const ratio = _.clamp(
      (momentumScrollValue - this.state.triggerPointApplication) / 400,
      0,
      1
    )

    return ratio * 80
  }

  render() {
    let page = this.props.pageContext.data.listing
    const department = this.props.pageContext.data.department

    const { theme } = this.props
    const { applicationTitle } = this.props.data.contentfulCareersLanding
    const { momentumScrollValue } = this.state

    page.heroRibbonText = page.categories.department
    page.heroHeadline = page.title
    page.heroDescription = page.shortDescription

    // console.log('[PAGE]', page)
    // console.log('[DEPARTMENT]', department)

    return (
      <Layout ref={this.mainNode}>
        <Helmet>
          <html lang="en" />
          <title>Baunfire | {page.text}</title>
          <meta name="description" content={page.descriptionPlain} />
          <meta property="og:title" content={page.text} />
          <meta property="og:description" content={page.descriptionPlain} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://www.baunfire.com/ogimage.jpg"
          />
          <meta
            property="og:url"
            content={`https://www.baunfire.com/careers/${page.sourceId}`}
          />
          <link
            rel="canonical"
            href={`https://www.baunfire.com/careers/${page.sourceId}`}
          />
        </Helmet>
        <PageTransition>
          <ScrollManager
            page={this.state.mainNode}
            scrollValue={this.state.scrollValue}
            updateScrollValue={this.updateScrollValue}
            updateMomentumScrollValue={this.updateMomentumScrollValue}
          >
            <section className="page-careers-detail">
              <HeroBlock
                page={{
                  heroRibbonText: department.title,
                  heroHeadline: page.text,
                  heroDescription:
                    'Work with amazing people to build and shape great brands.',
                }}
                momentumScrollValue={momentumScrollValue}
              />
              <HeadImage
                fluid={department.hero.fluid}
                momentumScrollValue={momentumScrollValue}
              />
              <section className="headline-container" ref={this.sectionContent}>
                <div className="content-container">
                  <h2 className="type-h1 headline-title">Overview</h2>
                  <div className="headline-description">
                    {Parser(page.description)}
                  </div>
                </div>
              </section>
              <section className="body-container">
                <div className="content-container">
                  {(page.lists || []).map((list, index) => (
                    <React.Fragment>
                      <h2>{list.text}</h2>
                      <ul>{Parser(list.content)}</ul>
                    </React.Fragment>
                  ))}
                </div>
              </section>
              <section
                className="application-container"
                ref={this.sectionApplication}
              >
                <Application page={page} title={applicationTitle} />
                <BrowserView>
                  <div className="application-filled-background" />
                </BrowserView>
              </section>
            </section>
            <section ref={this.sectionFooter}>
              <Footer />
            </section>
          </ScrollManager>
          <BrowserView>
            <SectionIndicator theme={theme}>Careers</SectionIndicator>
          </BrowserView>
        </PageTransition>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    contentfulCareersLanding(title: { eq: "Careers landing" }) {
      applicationTitle
    }
  }
`

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    loaded: state.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    backgroundBlack: () => dispatch({ type: 'BACKGROUND_BLACK' }),
    backgroundWhite: () => dispatch({ type: 'BACKGROUND_WHITE' }),
    backgroundRed: () => dispatch({ type: 'BACKGROUND_RED' }),
    noHomepageLoading: () => dispatch({ type: 'NO_HOMEPAGE_LOADING' }),
    flagLoad: () => dispatch({ type: 'LOADED' }),
  }
}

const ConnectedAbout = connect(mapStateToProps, mapDispatchToProps)(Career)

export default ConnectedAbout
